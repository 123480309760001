import Cookies from 'js-cookie';

export const useCSRFToken = () => {
  const getCSRFToken = () => Cookies.get('csrftoken') || '';

  return {
    getCSRFToken: () => getCSRFToken(),
    getCSRFHeader: () => ({
      'X-CSRFToken': getCSRFToken(),
    }),
  };
};
